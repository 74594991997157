































































































































































import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import ProgressBar from 'vuejs-progress-bar'
import { uploadStatus } from '@/components/Publish/publish'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'

// utils
import { toReadableDate } from '@/utils/time'
import { categoryList } from '@/components/Publish/publish'
import { secondsToHMS } from '@/components/Youtube/time'
import isEmpty from 'lodash.isempty'
import get from 'lodash.get'

// service
import AdoriService from '@/services/adori'
import { computed, SetupContext } from '@vue/composition-api'
import { useGetTrackTags } from '@/hooks/tag'
import { useGetTrackAudiograms } from '@/hooks/audiogram'
import Invalidate from '@/mixins/Invalidate'
import { audioQueryKeys } from '@/hooks/audio'
import { billingQueryKeys } from '@/hooks/billing'
import Billing from '@/mixins/Billing'

import mixpanel from 'mixpanel-browser'
import { useGetSubtitles } from '@/hooks/subtitle'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: { ProgressBar, TheToggleButton },
  setup(props: any, { root }: SetupContext) {
    const trackUid = computed(() =>
      get(
        root.$store.getters.selectedRssEpisodes.filter((obj: any) => obj.guid === props.guid),
        '[0].uid',
        false
      )
    )
    const isTrackEnabled = computed(() => !!trackUid.value)
    useGetTrackTags(trackUid, { enabled: isTrackEnabled })
    useGetTrackAudiograms(trackUid, {
      enabled: isTrackEnabled,
    })
    const { data: subtitleData } = useGetSubtitles(trackUid, { enabled: isTrackEnabled })
    return { subtitleData }
  },
})
export default class StepThreeYoutubeOnboarding extends Mixins(Invalidate, Billing) {
  @Prop(String) guid!: string
  @Prop() episode!: any
  @Prop(String) intent!: string

  @Getter selectedEpisodeList!: any
  @Getter selectedEpisodeSettings!: any
  @Getter selectedRssEpisodes!: any
  @Getter getRssEpisodeByGuid!: any
  @Getter uploadListStatus!: any
  @Getter networkId!: string
  @Getter createdYoutubeFeed!: any
  @Getter signinStatus!: any

  @Action toggleSelectEpisodeForUpload!: any
  @Action showYTPublishSetting!: Function
  @Action ytModalId!: Function
  @Action ytModalSetting!: any
  @Action getAudioTags!: any
  @Action pushNotification!: any
  @Action youtubeUpload!: any
  @Action youtubeUploadStatus!: any
  @Action getAudioTrackSubtitle!: Function
  @Action toggleCreditCardAlert!: Function

  uploadingImage = false
  file!: any
  imageId = ''
  posterImageId = ''
  downloadLoader = false
  interval!: any
  audioStatus = ''
  progressBarvalue = 0
  enableSubtitle = true
  isSubsEdited = false
  //   enableBgVideo = false
  subtitleData!: any

  @Watch('subtitleData')
  handleSub() {
    if (this.subtitleData) {
      this.isSubsEdited = get(this.subtitleData, 'isEdited', false) && get(this.subtitleData, 'textBlocks', []).length
    }
  }

  async mounted() {
    if (this.networkId && !this.uploadListStatus[this.selectedEpisodeSettings[this.guid].guid]) {
      if (!isEmpty(this.selectedRssEpisodes)) {
        this.$watch(
          () => this.tagPositions?.length,
          () => {
            if (this.tagPositions.length !== 0) {
              this.ytModalSetting({
                guid: this.guid,
                includeVisuals: true,
                includeChapters: true,
                chapterMarkers: this.tagsData(),
                hasTags: true,
              })
            }
          }
        )
        if (this.totalVisualElements === 0) {
          this.ytModalSetting({
            guid: this.guid,
            includeVisuals: false,
            includeChapters: false,
            hasTags: false,
            hasAudiograms: false,
            chapterMarkers: '',
          })
        } else {
          if (this.tagPositions.length !== 0) {
            this.ytModalSetting({
              guid: this.guid,
              includeVisuals: true,
              includeChapters: true,
              chapterMarkers: this.tagsData(),
              hasTags: true,
            })
          }
          if (this.audiogramPositions.length !== 0) {
            this.ytModalSetting({
              guid: this.guid,
              includeVisuals: true,
              includeChapters: true,
              hasAudiograms: true,
            })
          }
        }
      }

      const trackUid =
        this.episode.isRss === false && this.selectedRssEpisodes.length === 1
          ? get(this.selectedRssEpisodes, '[0].uid', '')
          : get(this.getRssEpisodeByGuid(this.guid), 'uid', '')
      const { isEdited, textBlocks } = this.subtitleData
        ? this.subtitleData
        : await this.getAudioTrackSubtitle({ trackUid: trackUid, status: true })
      this.isSubsEdited = isEdited && textBlocks.length
      this.enableSubtitle = this.selectedEpisodeSettings[this.guid].burnSubtitle
    }
  }

  @Watch('selectedRssEpisodes')
  async handleSelectedEpisode() {
    if (!isEmpty(this.selectedRssEpisodes)) {
      this.episode.isRss === false && this.selectedRssEpisodes.length === 1
        ? await this.getAudioTags(this.selectedRssEpisodes[0].uid)
        : await this.getAudioTags(this.getRssEpisodeByGuid(this.guid).uid)

      if (this.totalVisualElements === 0) {
        this.ytModalSetting({
          guid: this.guid,
          includeVisuals: false,
          includeChapters: false,
          hasTags: false,
          hasAudiograms: false,
          chapterMarkers: '',
        })
      } else {
        if (this.tagPositions.length !== 0) {
          this.ytModalSetting({
            guid: this.guid,
            includeVisuals: true,
            includeChapters: true,
            chapterMarkers: this.tagsData(),
            hasTags: true,
          })
        }
        if (this.audiogramPositions.length !== 0) {
          this.ytModalSetting({
            guid: this.guid,
            includeVisuals: true,
            includeChapters: true,
            hasAudiograms: true,
          })
        }
      }
    }
  }

  get ytFeedUid() {
    return get(this.createdYoutubeFeed, 'uid', '') || sessionStorage.getItem('feedUid')
  }

  get showDownloadbutton() {
    return this.progressBarvalue === 100 ? true : false
  }

  get title() {
    return this.selectedEpisodeSettings[this.guid].title
  }

  get size() {
    return `${this.selectedEpisodeSettings[this.guid].width}x${this.selectedEpisodeSettings[this.guid].height}`
  }

  get duration() {
    return secondsToHMS(
      this.selectedEpisodeSettings[this.guid].endTimeSec - this.selectedEpisodeSettings[this.guid].startTimeSec
    )
  }

  get description() {
    return this.selectedEpisodeSettings[this.guid].description
  }

  get isAudioUploading() {
    return this.uploadListStatus[this.selectedEpisodeSettings[this.guid].guid]
  }

  get includeVisuals() {
    if (
      !this.networkId ||
      this.uploadListStatus[this.selectedEpisodeSettings[this.guid].guid] ||
      !this.totalVisualElements
    )
      return false
    else return this.selectedEpisodeSettings[this.guid].includeVisuals
  }

  get keywords() {
    return this.selectedEpisodeSettings[this.guid].keywords
      ? this.selectedEpisodeSettings[this.guid].keywords.toString()
      : ''
  }

  get privacy() {
    return this.selectedEpisodeSettings[this.guid].privacy
  }

  get isVideoPodcast() {
    return this.selectedEpisodeSettings[this.guid].videoUrl
  }

  get category() {
    let categoryId = this.selectedEpisodeSettings[this.guid].category
    let index = categoryList.findIndex((item) => item.id === categoryId)
    return categoryList[index].title
  }

  get publish() {
    return this.selectedEpisodeSettings[this.guid].publish
  }

  get startTimeSec() {
    return this.selectedEpisodeSettings[this.guid].startTimeSec
  }

  get endTimeSec() {
    return this.selectedEpisodeSettings[this.guid].endTimeSec
  }

  get audio() {
    return this.episode
  }

  get audioPreviewImage() {
    if (this.imageId) {
      return this.file.url
    } else {
      return this.selectedEpisodeSettings[this.guid].thumbnailSrc
    }
  }

  get audioDate() {
    if (this.audio.publishedOn) {
      return toReadableDate(this.audio.publishedOn)
    }

    if (this.audio.publishTime) {
      return toReadableDate(this.audio.publishTime)
    }
    return toReadableDate(this.audio.createdOn)
  }

  get audioDescription() {
    return this.audio.description
  }

  get tagPositions() {
    if (!this.networkId || this.uploadListStatus[this.selectedEpisodeSettings[this.guid].guid]) return []
    const tagPositions =
      this.episode.isRss === false && this.selectedRssEpisodes.length === 1
        ? this.$store.getters.tagPositions(get(this.selectedRssEpisodes, '[0].uid', '')) || []
        : this.$store.getters.tagPositions(get(this.getRssEpisodeByGuid(this.guid), 'uid', '')) || []
    return tagPositions
  }

  get audiogramPositions() {
    if (!this.networkId || this.uploadListStatus[this.selectedEpisodeSettings[this.guid].guid]) return []
    const audiogramPositions =
      this.episode.isRss === false && this.selectedRssEpisodes.length === 1
        ? this.$store.getters.audiogramPositions(get(this.selectedRssEpisodes, '[0].uid', '')) || []
        : this.$store.getters.audiogramPositions(get(this.getRssEpisodeByGuid(this.guid), 'uid', '')) || []
    return audiogramPositions
  }

  get totalVisualElements() {
    return this.tagPositions.length + this.audiogramPositions.length
  }

  chooseStyle() {
    const trackUid =
      this.episode.isRss === false && this.selectedRssEpisodes.length === 1
        ? get(this.selectedRssEpisodes, '[0].uid', '')
        : get(this.getRssEpisodeByGuid(this.guid), 'uid', '')
    sessionStorage.setItem('uid', trackUid)
    this.$store.dispatch('showSubtitleStyle')
  }

  tagsData() {
    let tagTimestamps = '\n\n⌛Timestamps:\n00:00 - Intro\n'
    this.tagPositions.map((e: any) => {
      let totalSeconds = e.offsetMillis / 1000
      if (this.startTimeSec < totalSeconds && this.endTimeSec > totalSeconds) {
        const tagDuration =
          e.offsetMillis - this.startTimeSec * 1000 > 3599000
            ? new Date(e.offsetMillis - this.startTimeSec * 1000).toISOString().substr(12, 7)
            : new Date(e.offsetMillis - this.startTimeSec * 1000).toISOString().substr(14, 5)

        !['choose'].includes(e.tag.actions) && (tagTimestamps += `${tagDuration} - `)

        e.tag.actions === 'navigate' &&
          !isEmpty(e.tag.location) &&
          (tagTimestamps += `${
            e.tag.tagTitle ? e.tag.tagTitle : 'Location'
          } → ${`https://www.google.com/maps/place/${e.tag.location.location.replace(/(.*):/, '')}`} \n`)

        e.tag.actions === 'call' &&
          !isEmpty(e.tag.contact) &&
          (tagTimestamps += `Contact → ${e.tag.contact.name} → ${e.tag.contact.phoneNumber} \n`)

        e.tag.actions === 'create_note' &&
          !isEmpty(e.tag.notetext) &&
          (tagTimestamps += `${e.tag.notetext.length < 30 ? e.tag.notetext : e.tag.notetext.substr(0, 27) + '...'} \n`)

        if (e.tag.actions === 'click' && e.tag.url) {
          tagTimestamps += `${e.tag.tagTitle ? e.tag.tagTitle : 'Link'} → ${e.tag.url}\n`
        } else if (e.tag.actions === 'click') {
          tagTimestamps += `${e.tag.tagTitle ? e.tag.tagTitle : 'Image'}\n`
        }
        e.tag.actions === 'buy' &&
          (tagTimestamps += `${e.tag.buy.name ? e.tag.buy.name : e.tag.buy.actionText} → ${e.tag.buy.actionUrl}\n`)
      }
    })
    return tagTimestamps
  }

  async downloadVideo() {
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }
    this.$emit('removeOnload')
    if (this.networkId) {
      this.$gtag.event('download-video')
      if (this.progressBarvalue === 100) {
        const videoDownloadLink = this.$refs.videoDownloadRef as HTMLElement
        videoDownloadLink.click()
      } else {
        this.downloadLoader = true
        const video = await this.uploadVideo()
        if (video.status === uploadStatus.QUEUED || video.status === uploadStatus.STARTED) {
          this.interval = setInterval(async () => {
            const getRes = await this.youtubeUploadStatus({
              network_id: this.networkId,
              task_id: video.id,
            })
            if (getRes.status === uploadStatus.QUEUED || getRes.status === uploadStatus.STARTED) {
              this.audioStatus = getRes.status
              this.progressBarvalue = Math.ceil(getRes.progress)
            } else if (getRes.status === uploadStatus.FINISHED) {
              this.audioStatus = getRes.status
              this.progressBarvalue = 100
              this.downloadVideoFile(getRes.id)
              this.downloadLoader = false
              clearInterval(this.interval)
            } else {
              this.downloadLoader = false
              clearInterval(this.interval)
            }
          }, 6000)
        }
      }
    } else {
      this.$store.commit('setTriggerLogin')
    }
  }

  async uploadVideo() {
    try {
      const uploadType =
        (this.selectedEpisodeSettings[this.guid].hasTags || this.selectedEpisodeSettings[this.guid].hasAudiograms) &&
        this.selectedEpisodeSettings[this.guid].includeVisuals
          ? 'INTERACTIVE'
          : 'NON_INTERACTIVE'

      const trackUid =
        this.selectedEpisodeList.length === 1 && this.selectedEpisodeList[0].isRss === false
          ? this.selectedRssEpisodes[0].uid
          : this.getRssEpisodeByGuid(this.guid).uid

      let commonPayload: any = {
        trackUid: trackUid,
        categoryId: this.selectedEpisodeSettings[this.guid].category,
        privacy: this.selectedEpisodeSettings[this.guid].privacy,
        title: this.selectedEpisodeSettings[this.guid].title.substring(0, 99),
        width: this.selectedEpisodeSettings[this.guid].width,
        height: this.selectedEpisodeSettings[this.guid].height,
        startTimeSec: this.selectedEpisodeSettings[this.guid].startTimeSec,
        endTimeSec: this.selectedEpisodeSettings[this.guid].isFullDuration
          ? null
          : this.selectedEpisodeSettings[this.guid].endTimeSec,
        type: uploadType,
        posterImageId: this.selectedEpisodeSettings[this.guid].posterImageId,
        publishToYoutube: false,
        burnSubtitle: this.selectedEpisodeSettings[this.guid].burnSubtitle,
        backgroundVideo: this.selectedEpisodeSettings[this.guid].backgroundVideo,
      }

      if (this.selectedEpisodeSettings[this.guid].hasAudiograms) {
        const audiogramId = this.$store.getters.audiogramPositions(trackUid)[0].audiogramId
        commonPayload = { ...commonPayload, audiogramId: audiogramId }
      }

      const payload: any = {
        data: {
          ...commonPayload,
        },
        params: {
          network_id: this.networkId,
        },
      }
      mixpanel.track('Download Video')
      webengage?.track('download-video', { data: payload })
      this.signinStatus[this.ytFeedUid]?.youtube && (payload.params['showUid'] = this.ytFeedUid)
      const data = await this.youtubeUpload(payload)
      this.pushNotification({
        text: `Downloading in progress. Please wait.`,
        type: 'SUCCESS',
      })
      sessionStorage.setItem(trackUid, trackUid)
      if (this.ytFeedUid) {
        this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.ytFeedUid}`])
        this.queryClient.invalidateQueries([audioQueryKeys.YTUNPUBLISHED, `${this.ytFeedUid}`])
      } else this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
      this.queryClient.invalidateQueries(billingQueryKeys.BILLING_INVOICES)
      this.queryClient.invalidateQueries(billingQueryKeys.BILLING_USAGE)
      return data
    } catch (error) {
      this.downloadLoader = false
    }
  }

  async downloadVideoFile(id: any) {
    const params = {
      network_id: this.networkId,
      task_id: id,
    }

    const data: any = await AdoriService.youtube_download(params)
    if (!data.url) {
      this.pushNotification({ text: 'No URL available', type: 'ERROR' })
      return
    }

    if (this.$refs['videoDownloadRef']) {
      //@ts-ignore
      this.$refs['videoDownloadRef'].href = data.url
      const audioDownloadLink = this.$refs.videoDownloadRef as HTMLElement
      audioDownloadLink.click()
    }
  }

  editPublish() {
    this.ytModalId(this.guid)
    this.showYTPublishSetting(this.imageId)
  }

  imageClicked() {
    if (this.networkId) {
      this.$store.dispatch('showFileUploader', {
        accept: 'image/*',
        onChange: this.loadImage,
        dontCheck: true,
      })
    } else {
      this.$store.commit('setTriggerLogin')
    }
  }

  async loadImage() {
    const file = this.$store.getters.selectedFile
    if (this.bytesToMegaBytes(file.size) > 2) {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload image lesser than 2MB',
        type: 'WARNING',
      })
      return
    }
    if (file) {
      this.uploadingImage = true
      const img: any = await AdoriService.uploadImage(this.networkId, file)
      this.file = img
      this.imageId = img.id
      this.posterImageId = img.id
      this.ytModalSetting({
        guid: this.guid,
        thumbnailSrc: img.url,
        posterImageId: img.id,
      })
      Vue.set(img, 'thumbnailSrc', img.url)
      this.uploadingImage = false
    }
  }

  bytesToMegaBytes(bytes: any) {
    return bytes / (1024 * 1024)
  }

  get showProgressBar() {
    return this.downloadLoader ? true : false
  }

  options = {
    text: {
      color: '#FFFFFF',
      shadowEnable: true,
      shadowColor: '#000000',
      fontSize: 8,
      fontFamily: 'Helvetica',
      dynamicPosition: false,
      hideText: false,
    },
    progress: {
      color: '#de1a23',
      backgroundColor: 'grey',
      inverted: false,
    },
    layout: {
      height: 10,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: 'line',
    },
  }

  @Watch('enableSubtitle')
  async handleSubtitle() {
    this.ytModalSetting({
      guid: this.guid,
      burnSubtitle: this.enableSubtitle,
    })
  }

  //   @Watch('enableBgVideo')
  //   async handleBgVideo() {
  //     this.ytModalSetting({
  //       guid: this.guid,
  //       backgroundVideo: this.enableBgVideo,
  //     })
  //   }
}
