var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-adori-gray w-100 flex justify-start br2 mb3" },
    [
      _c("div", { staticClass: "w-10 mr2 br2 image-container" }, [
        _c("img", { attrs: { src: _vm.audioPreviewImage, alt: "" } }),
      ]),
      _c(
        "div",
        {
          staticClass: "mr2 mt3 w-25",
          attrs: { id: "edit_audio_tour_step_1" },
        },
        [
          _c("div", { staticClass: "name flex justify-center pb1 truncate" }, [
            _c("span", { attrs: { title: _vm.audio.name } }, [
              _vm._v(
                _vm._s(
                  _vm.audio
                    ? _vm.truncate(_vm.audio.name, 25)
                    : "Loading audio details..."
                )
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "vue-slider",
                _vm._b(
                  {
                    ref: "slider",
                    attrs: { max: _vm.audioInSecs },
                    on: { "drag-end": _vm.dragend, callback: _vm.callback },
                    model: {
                      value: _vm.sliderValue,
                      callback: function ($$v) {
                        _vm.sliderValue = $$v
                      },
                      expression: "sliderValue",
                    },
                  },
                  "vue-slider",
                  _vm.options,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "current-total-time pb1 flex justify-center" },
            [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.sliderValueinHHMMSS) +
                    " | " +
                    _vm._s(_vm.sliderEndTime)
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "audio-control flex justify-center" }, [
            _c("audio", { ref: "audio", attrs: { preload: "auto" } }, [
              _c("source", { attrs: { src: _vm.audioSrc } }),
            ]),
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mr2 v-mid dim pointer",
                on: { click: _vm.seekBackward },
              },
              [_vm._v(" replay_10 ")]
            ),
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mr2 v-mid dim pointer",
                on: { click: _vm.togglePlay },
              },
              [_vm._v("\n        " + _vm._s(_vm.playButtonIcon) + "\n      ")]
            ),
            _c(
              "i",
              {
                staticClass:
                  "material-icons light-gray f3 mr2 v-mid dim pointer",
                on: { click: _vm.seekForward },
              },
              [_vm._v(" forward_10 ")]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider mr2" }),
      _c(
        "div",
        {
          staticClass: "flex flex-column justify-center items-center w-25",
          attrs: { id: "edit_audio_tour_step_2" },
        },
        [
          _c("div", { staticClass: "flex mb3 w-100" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-column items-center justify-center w-50 mr2",
              },
              [
                _c("label", {}, [_vm._v("Trim Start")]),
                _c(
                  "date-picker",
                  {
                    staticClass: "adori-date-picker",
                    staticStyle: { width: "100px" },
                    attrs: {
                      format: "HH:mm:ss",
                      "value-type": "format",
                      type: "time",
                      placeholder: "HH:MM:SS",
                      "popup-style": _vm.datePickerStyle,
                      "time-select-options": _vm.timeSelectOptions,
                    },
                    on: { clear: _vm.insertClipStart },
                    model: {
                      value: _vm.beginTime,
                      callback: function ($$v) {
                        _vm.beginTime = $$v
                      },
                      expression: "beginTime",
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons light-gray f4",
                        attrs: { slot: "calendar-icon" },
                        slot: "calendar-icon",
                      },
                      [_vm._v("schedule")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-column items-center justify-center w-50 mr2",
              },
              [
                _c("label", {}, [_vm._v("Trim End")]),
                _c(
                  "date-picker",
                  {
                    staticClass: "adori-date-picker",
                    staticStyle: { width: "100px" },
                    attrs: {
                      format: "HH:mm:ss",
                      "value-type": "format",
                      type: "time",
                      placeholder: "HH:MM:SS",
                      "popup-style": _vm.datePickerStyle,
                      "time-select-options": _vm.timeSelectOptions,
                    },
                    on: { clear: _vm.insertClipEnd },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons light-gray f4",
                        attrs: { slot: "calendar-icon" },
                        slot: "calendar-icon",
                      },
                      [_vm._v("schedule")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex flex-column center" }, [
            _vm._v("Duration: " + _vm._s(_vm.duration)),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider mr2" }),
      _c(
        "div",
        { staticClass: "mr2 w-20", attrs: { id: "edit_audio_tour_step_3" } },
        [
          _c(
            "div",
            { staticClass: "flex pa2 w-100 justify-center" },
            [
              _c("div", { staticClass: "flex flex-column w-50 ml4 mb1" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Width")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.width,
                      expression: "width",
                    },
                  ],
                  staticClass: "input bg-adori-very-light-gray",
                  attrs: {
                    type: "number",
                    placeholder: "1280",
                    max: "3840",
                    min: "426",
                    required: "",
                    readonly: _vm.isFreeAccount,
                  },
                  domProps: { value: _vm.width },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "width")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.width = $event.target.value
                    },
                  },
                }),
              ]),
              _c("span", { staticClass: "cross" }, [_vm._v("X")]),
              _c("div", { staticClass: "flex flex-column w-50" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Height")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.height,
                      expression: "height",
                    },
                  ],
                  staticClass: "input bg-adori-very-light-gray",
                  attrs: {
                    type: "number",
                    placeholder: "720",
                    max: "2160",
                    min: "240",
                    required: "",
                    readonly: _vm.isFreeAccount,
                  },
                  domProps: { value: _vm.height },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "height")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.height = $event.target.value
                    },
                  },
                }),
              ]),
              _c("BasePopupMenu", {
                staticClass: "dropdown",
                attrs: {
                  icon: "arrow_drop_down",
                  items: _vm.videoResolutionMenu,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex justify-center" }, [
            _c(
              "div",
              {
                staticClass: "bn w-46 h-46 landscape bg-center pointer mr1 pv2",
                class: { isActive: _vm.active === "landscape" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActive("landscape")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "w-100 h-100 bg-adori-very-light-gray",
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "bn w-46 h-46 square bg-center pointer pa2",
                class: { isActive: _vm.active === "square" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActive("square")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "w-100 h-100 bg-adori-very-light-gray",
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "bn w-46 h-46 portrait bg-center pointer ph2",
                class: { isActive: _vm.active === "portrait" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActive("portrait")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "w-100 h-100 bg-adori-very-light-gray",
                }),
              ]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c(
        "div",
        {
          staticClass:
            "flex flex-column items-center justify-center w-20 mobile-hide relative",
          class: _vm.uploadListStatus[_vm.episode.guid]
            ? "not-allowed"
            : "pointer",
          attrs: { id: "edit_audio_tour_step_4" },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-column items-center" },
            [
              _c("BaseButtonRed", {
                staticClass: "btn2 mb2",
                attrs: {
                  text: _vm.uploadListStatus[_vm.episode.guid]
                    ? "Audio Processing"
                    : "Advanced Editor",
                  icon: "view_timeline",
                  onClick: function () {
                    _vm.uploadListStatus[_vm.episode.guid]
                      ? ""
                      : _vm.handleEnhance()
                  },
                  disabled: _vm.uploadListStatus[_vm.episode.guid],
                  loading: _vm.uploadListStatus[_vm.episode.guid],
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "divider-top" }),
          _vm.trackUid
            ? _c("BaseButtonRed", {
                staticClass: "btn2 mt2",
                attrs: {
                  text: "Video Preview",
                  icon: "visibility",
                  onClick: function () {
                    _vm.isPreview = !_vm.isPreview
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isPreview
        ? _c("ModalVideoPreview", {
            attrs: {
              currentTime: _vm.currentTime,
              trackUid: _vm.trackUid,
              backgroundVideo: _vm.backgroundVideo,
              audiogramPositions: _vm.audiogramPositions || [],
              tagsInTrack: _vm.tagStamps,
              coverImage: _vm.imagePreview,
              isPlaying: _vm.isPlaying,
            },
            on: {
              close: function ($event) {
                _vm.isPreview = !_vm.isPreview
              },
              "toggle-play": _vm.togglePlay,
              "scroll-timeline": function ($event) {
                return _vm.onCurrentTime($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }